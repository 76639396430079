import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`I'm obsessed with this business model`}</h1>
    <p>{`Over the past few months, I’ve become quite obsessed with the idea of
`}<strong parentName="p">{`solopreneurship`}</strong>{`.`}</p>
    <p>{`This email explains what it is….why it’s cool….and how I’m doing it..`}</p>
    <p><strong parentName="p">{`Chapter 1: WHY ITS GOOD`}</strong><br parentName="p"></br>{`
`}{`First - why solo?`}</p>
    <p>{`Well… having a bunch of people in your company is a pain in the ass.`}</p>
    <ul>
      <li parentName="ul">{`More people = more headaches`}</li>
      <li parentName="ul">{`More people = more monthly payroll expenses`}</li>
      <li parentName="ul">{`More people = more dilution % of equity`}</li>
      <li parentName="ul">{`More people = more time in meetings just to make sure ‘everyone is on the same
page’`}</li>
      <li parentName="ul">{`More people = more HR issues`}</li>
    </ul>
    <p>{`I’ve been in companies of many sizes. I’ve worked alone. 2 person teams. 3
person teams. 10-20 person startup. 1000 person companies & even 300k+ person
companies.`}</p>
    <p>{`For me - by far the most fun is at the 1-3 person phase. I think most
entrepreneurs will agree. Big company is good for the ego, but causes a lot of
headaches.`}</p>
    <p>{`So if people think it’s so great - why don’t they do it?`}</p>
    <p>{`Good question.`}</p>
    <p><strong parentName="p">{`Chapter 2: WHY IT'S POSSIBLE`}</strong><br parentName="p"></br>{`
`}{`In the past - you needed people to do all the things a company needs to do. Building,
designing, selling, financing etc..`}</p>
    <p>{`These are all different skill sets, and so it required different people to do
it.`}</p>
    <p>{`So why is that changing?`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Outsourcing`}</strong>{` - you can now outsource pretty much anything you don’t want
to do via upwork, fiverr etc..`}</li>
      <li parentName="ol"><strong parentName="li">{`Tools & Automation`}</strong>{` - we now have no-code tools that give you superpowers.
Things that used to require people, now can be done with tech. And tech that
needed engineers can now be done by anyone thanks to no-code.`}</li>
      <li parentName="ol"><strong parentName="li">{`Leverage`}</strong>{` - As the internet gets bigger, and more mature - running your
little “coffee shop” on the internet can now be worth `}{`$`}{`1m+/year in profits,
because you’re able to serve customers from all over the world and take
payments effortlessly.`}</li>
    </ol>
    <p>{`This wasn’t true 10 years ago, but it is true now.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "457px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "87.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAASABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAECAwX/xAAXAQEBAQEAAAAAAAAAAAAAAAAAAQIE/9oADAMBAAIQAxAAAAH1ry6b59ISwMhf/8QAGxAAAgEFAAAAAAAAAAAAAAAAATECABASIUH/2gAIAQEAAQUC6baykgqKiv/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEABj8CH//EABwQAAICAgMAAAAAAAAAAAAAAAARATEQIUFRof/aAAgBAQABPyHfRDLVkULilsS3mSzB/9oADAMBAAIAAwAAABC0P/7/xAAWEQEBAQAAAAAAAAAAAAAAAAABESD/2gAIAQMBAT8QQpMf/8QAFhEAAwAAAAAAAAAAAAAAAAAAESAh/9oACAECAQE/EKE//8QAHBABAQEAAwEBAQAAAAAAAAAAAREAITFBUXGR/9oACAEBAAE/EBvWvR7ufYfTqios8wtqdCrwnw81IuPDUc6E0O8P4bs/d//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Old World vs. New World",
          "title": "Old World vs. New World",
          "src": "/static/d19c2c74be4ba8e9d5a27ce6bad18664/ff7b0/old-world-new-world.jpg",
          "srcSet": ["/static/d19c2c74be4ba8e9d5a27ce6bad18664/a6407/old-world-new-world.jpg 400w", "/static/d19c2c74be4ba8e9d5a27ce6bad18664/ff7b0/old-world-new-world.jpg 457w"],
          "sizes": "(max-width: 457px) 100vw, 457px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`As companies shrink. From thousands → hundreds → tens → down to just 1-2 people.`}</p>
    <p>{`You’re stripping away all the “undifferentiated” functions. Like legal, finance,
sales, etc.. and even in many cases, design and engineering.`}</p>
    <p>{`The people `}<strong parentName="p">{`inside`}</strong>{` the company focus ONLY on the secret sauce. The part that
makes your company stand out. Every undifferentiated function (eg. paying
payroll, running servers, etc..) gets outsourced.`}</p>
    <p><strong parentName="p">{`Chapter 3: Examples`}</strong></p>
    <p>{`Let’s look at some examples.`}</p>
    <p><strong parentName="p">{`Media`}</strong></p>
    <p>{`Let’s compare two of the biggest “talk shows” on earth. The Tonight Show with
Jimmy Fallon vs. Joe Rogan.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "544px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "87.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAACu0lEQVQ4y52UzU9TQRTF21dbodba0g8Cpa8pwS71HzAhUBJFIbowqBsXfkQTViZSRQVRQAqtlECIO93gShJdGzeYuEA0JoqARQSBUEoRkNJCA33HmelrH21BjIuT95HMb86998zIZDIZdpVczp56K4/2hSh8a8Dj3wI6V5ES/SYSxPdRWXKxnCxWKBR7A1fi8C5vMTF4mIEFcSMJaDKZYLfbUVJyGEVFRalNMoHe5U00jPzEveFJ1H/yo3F0mkIF0akEpOK4hEuO4/5aMnXZSR0ubTKnSYdZwH/qIYH5IoCbyLMO+MLYuYdUdt6C3Jyc3YG0X0TNE0G86HuOJ/0v0UZcdhJgmsNkr+5cPofTzmOp8tOB66Q0AT1xoMU/i6dVJ/Cs7iY8MaArAkGcuuRwX+5BVFdWoqysAjJOKQ6FSwHdwQi6N4DGsRmUXqlFpcOBmqoqXO/rR/3AkOB68w6PphYloMZYiNKKk3Aer4bWbE243ubQPRdGDwCXrxcm8o/nTSg8dACOI0fhWYgK4rDEkil0vxqnnKW4euEsiotLsmNDHHpJea9eD+DapYsorz2P8pozqGloQndshxzuJHlGD9lQomB9JBD0bAG9cWnKKaBSqYTRaGThNhgMTEnY9il30bjMr6HJP4fWmSU0Ty4QhbJjo1KpGCQ/Px95eXkMngDKs4BtgTAefA+gZZoAp0JEi9nAHJI9nudRUFAAq9UKi8UinZaMYFO1ziyzadPj1x6M0qCn91CtVkOv10Or1TJpNJoEMCuHxOHcKouQez7C3Hp+xejxSw+22Wwmky2GzWZjF4ODZEyn06U57AhtsHN7a2gEN95+wO2PY3C9/4q6wS9oGg8ItB17nuXM24Yu8q5s4eGPIHEaxv1vs+zm6ViMCdQx2fA/LgdSGn1SOO3l3c8TcA0OC7SfNNh/ANCwFdSs0ymyAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Old Media vs. New Media",
          "title": "Old Media vs. New Media",
          "src": "/static/7c0d660562873dfbf8080c4e2c5b3c82/cfd82/old-media-new-media.png",
          "srcSet": ["/static/7c0d660562873dfbf8080c4e2c5b3c82/d9f49/old-media-new-media.png 400w", "/static/7c0d660562873dfbf8080c4e2c5b3c82/cfd82/old-media-new-media.png 544w"],
          "sizes": "(max-width: 544px) 100vw, 544px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Joe is a solopreneur. It’s just him and his sidekick Jamie (powerful young
jamie).`}</p>
    <p>{`Even without the big spotify licensing deal
($100m+), Joe was comfortably pulling in $20m+ per year, with 90% margins on his
podcast, and didn’t have to answer to some suit from the network`}</p>
    <p>{`(`}{`*`}{`cue the nervous jimmy fallon laugh`}{`*`}{`)`}</p>
    <p>{`He’s not alone. The Tim Ferriss podcast is similar. The Dave Ramsey Show, Steven
Crowley, Ben Shapiro, Alex Jones etc..`}</p>
    <p>{`Oh and it’s not just podcasting.`}</p>
    <p>{`Ben Thompson writes “Stratechery” (tech strategy daily blog). The fucking guy
lives in Taiwan, writes a blog a day, and pulls > `}{`$`}{`3M+ per year in profits off
his paid subscribers.`}</p>
    <p>{`These people are kings of a new category: solo media`}</p>
    <p>{`They are one-person media companies. Fans subscribe directly. They publish onto
big platforms like YouTube, Email, or iTunes. They have 1 person behind the
scenes doing the editing/publishing etc..`}</p>
    <p><strong parentName="p">{`Chapter 4: My Blueprint`}</strong></p>
    <p>{`There are many types of solopreneurs. Some are “indie hackers” building small
pieces of software that pull in $500k-$3M a year.`}</p>
    <p>{`I’m not an engineer - so I look for blueprints that match my talents.`}</p>
    <p>{`I like to be a `}<strong parentName="p">{`systems thinker`}</strong>{`. I don’t build products, I build systems. I
design systems with positive feedback loops (aka a Flywheel)`}</p>
    <p>{`Here’s the generic solopreneur Flywheel`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "445px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "71.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAgAF/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/aAAwDAQACEAMQAAAB1izedxhf/8QAFxABAQEBAAAAAAAAAAAAAAAAABEBEP/aAAgBAQABBQLV7Ff/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAbEAACAgMBAAAAAAAAAAAAAAABEQAhMVFhQf/aAAgBAQABPyEiwl2YsXyMbhDIMqKrcDeT/9oADAMBAAIAAwAAABDE/wD/xAAXEQADAQAAAAAAAAAAAAAAAAABEBEh/9oACAEDAQE/EBJq/8QAFREBAQAAAAAAAAAAAAAAAAAAECH/2gAIAQIBAT8Qp//EAB0QAQACAgIDAAAAAAAAAAAAAAEAETFRIWFBkdH/2gAIAQEAAT8QAFBlOvsvZRmaUp1zOp7jiOBEcJGzh05U1fiGbKT/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Solopreneur Flywheel",
          "title": "Solopreneur Flywheel",
          "src": "/static/08408ee85ce9ff984f49ac8a9425fc05/3e119/solopreneur-flywheel.jpg",
          "srcSet": ["/static/08408ee85ce9ff984f49ac8a9425fc05/a6407/solopreneur-flywheel.jpg 400w", "/static/08408ee85ce9ff984f49ac8a9425fc05/3e119/solopreneur-flywheel.jpg 445w"],
          "sizes": "(max-width: 445px) 100vw, 445px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`The key is “niche authority”. Every piece of content, every follower, and every
dollar you make is building up your authority in that niche.`}</p>
    <p>{`The bigger your authority, the more unique content you can create, the more
followers you gain, and the more your diehard fans will pay for your stuff.`}</p>
    <p>{`Let me give you another example.`}</p>
    <p>{`Recently I’ve been digging into a successful Solopreneur who’s bringing in over
`}{`$`}{`4M a year through this flywheel: Anthony “Pomp” Pompliano.`}</p>
    <p>{`I met pomp when I went on his podcast a few months ago. Good dude. And he’s a
content machine.`}</p>
    <p>{`What’s interesting is that before 2017, Pomp was just another guy working in
tech. He worked at facebook, and did a small startup, but wasn’t a household
name.`}</p>
    <p>{`I asked him how he got big, and he said he decided to `}<strong parentName="p">{`go all-in`}</strong>{` on twitter
that year. So he started tweeting about all kinds of business stuff...and
noticed his bitcoin tweets were going viral. The algorithm liked bitcoin, so he
cut the rest, and started tweeting all about Bitcoin.`}</p>
    <p>{`I researched his old tweets using some tool called
`}<a parentName="p" {...{
        "href": "http://tweettunnel.com/reverse3.php?ga=0&b=y&id=339061487&pn=2"
      }}><strong parentName="a">{`TweetTunnel`}</strong></a>{`
(probably a solopreneur behind this app). And he was totally inactive
before 2017. Using
`}<a parentName="p" {...{
        "href": "https://socialblade.com/twitter/user/apompliano"
      }}><strong parentName="a">{`Social Blade`}</strong></a>{` you can see
his follower growth take off in 2018...at the same time Bitcoin price spiked
from $2k to $20k`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "862px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "84.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAARABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIDAQX/xAAXAQEBAQEAAAAAAAAAAAAAAAAAAQIE/9oADAMBAAIQAxAAAAH187nfPolZQOBf/8QAGhAAAgIDAAAAAAAAAAAAAAAAABEBAhAgIf/aAAgBAQABBQIi2EI5p//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABUQAQEAAAAAAAAAAAAAAAAAACBR/9oACAEBAAY/AhD/AP/EABwQAAICAgMAAAAAAAAAAAAAAAERAFEQICFBcf/aAAgBAQABPyEmoxrlFYPYQV9QG40//9oADAMBAAIAAwAAABB/33z/xAAXEQEAAwAAAAAAAAAAAAAAAAARASAh/9oACAEDAQE/EIDaf//EABURAQEAAAAAAAAAAAAAAAAAACAh/9oACAECAQE/EKP/xAAcEAEAAgIDAQAAAAAAAAAAAAABESEAQSAxUXH/2gAIAQEAAT8QcgIq3LQbww6ZBtPTJHpMsEZFY0zrACkO9pPussRc+8P/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Pomp's Flywheel",
          "title": "Pomp's Flywheel",
          "src": "/static/bb230cc289f97922ac7534cca39c4469/6c4d9/pomp-flywheel.jpg",
          "srcSet": ["/static/bb230cc289f97922ac7534cca39c4469/a6407/pomp-flywheel.jpg 400w", "/static/bb230cc289f97922ac7534cca39c4469/a24e6/pomp-flywheel.jpg 800w", "/static/bb230cc289f97922ac7534cca39c4469/6c4d9/pomp-flywheel.jpg 862w"],
          "sizes": "(max-width: 862px) 100vw, 862px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Pomp has cranked the hell outta his flywheel in just a 3 year period.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "844px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "71.74999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAMF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAd9JFUh//8QAGBAAAgMAAAAAAAAAAAAAAAAAAAESEyD/2gAIAQEAAQUCxBFaP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAEFAQAAAAAAAAAAAAAAAAACIDFBkZL/2gAIAQEABj8CZKtL6P/EABwQAAEDBQAAAAAAAAAAAAAAAAABECERMWGR8f/aAAgBAQABPyF7xVZOxMu4/9oADAMBAAIAAwAAABCIP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAAMAAgMBAAAAAAAAAAAAAAABESHwMWFxwf/aAAgBAQABPxBcDboq+ivNDBxwb3dP0036f//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Pomp's Twitter Stats",
          "title": "Pomp's Twitter Stats",
          "src": "/static/fde5c6a525e917fa43b3bedc158aa55c/0004b/pomp-twitter-stats.jpg",
          "srcSet": ["/static/fde5c6a525e917fa43b3bedc158aa55c/a6407/pomp-twitter-stats.jpg 400w", "/static/fde5c6a525e917fa43b3bedc158aa55c/a24e6/pomp-twitter-stats.jpg 800w", "/static/fde5c6a525e917fa43b3bedc158aa55c/0004b/pomp-twitter-stats.jpg 844w"],
          "sizes": "(max-width: 844px) 100vw, 844px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`This is similar to the flywheel of Tim Ferriss & Sophia Amoruso`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "820px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "77.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAgADBf/EABYBAQEBAAAAAAAAAAAAAAAAAAECBP/aAAwDAQACEAMQAAAB6xRvPpGT/8QAGBABAAMBAAAAAAAAAAAAAAAAAAEQESH/2gAIAQEAAQUCRNYxx//EABURAQEAAAAAAAAAAAAAAAAAABAh/9oACAEDAQE/AYf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAdEAACAQQDAAAAAAAAAAAAAAABIQAQETFRQWGB/9oACAEBAAE/ISdemJvmhbrBEUJDmDQz/9oADAMBAAIAAwAAABDX3//EABcRAAMBAAAAAAAAAAAAAAAAAAEQITH/2gAIAQMBAT8QEXV//8QAFhEAAwAAAAAAAAAAAAAAAAAAEBEh/9oACAECAQE/EKx//8QAHRABAQACAgMBAAAAAAAAAAAAAREAITFBEFGB0f/aAAgBAQABPxBxAEdowDKk6LEOTq+FhGwo7P3AcS1bt+OOdaX3n//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Tim Ferriss' Flywheel",
          "title": "Tim Ferriss' Flywheel",
          "src": "/static/086388015884f2c6aa05623187bfef7a/29dad/tim-ferriss-flywheel.jpg",
          "srcSet": ["/static/086388015884f2c6aa05623187bfef7a/a6407/tim-ferriss-flywheel.jpg 400w", "/static/086388015884f2c6aa05623187bfef7a/a24e6/tim-ferriss-flywheel.jpg 800w", "/static/086388015884f2c6aa05623187bfef7a/29dad/tim-ferriss-flywheel.jpg 820w"],
          "sizes": "(max-width: 820px) 100vw, 820px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "824px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "76.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBf/EABYBAQEBAAAAAAAAAAAAAAAAAAACBP/aAAwDAQACEAMQAAAB15VW87ih/8QAFxAAAwEAAAAAAAAAAAAAAAAAAAIREP/aAAgBAQABBQIV7sKf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFhAAAwAAAAAAAAAAAAAAAAAAACAx/9oACAEBAAY/AiL/AP/EABwQAQACAQUAAAAAAAAAAAAAAAEAEVEQITFBkf/aAAgBAQABPyFeKlVbbNU6JaOJ5zXcBhn/2gAMAwEAAgADAAAAEA/v/8QAFxEAAwEAAAAAAAAAAAAAAAAAARARIf/aAAgBAwEBPxASav/EABURAQEAAAAAAAAAAAAAAAAAABAh/9oACAECAQE/EKf/xAAeEAEBAAIBBQEAAAAAAAAAAAABEQAhkTFBUXHB0f/aAAgBAQABPxB6Ja7V0HfEjHhCl6m8ETSPrJgx4OEGibR9Mk0XH7n/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Sophia Amoruso's Flywheel",
          "title": "Sophia Amoruso's Flywheel",
          "src": "/static/88d2e112d4c564f3f6b407c6f45017ef/4dc06/sophia-amoruso-flywheel.jpg",
          "srcSet": ["/static/88d2e112d4c564f3f6b407c6f45017ef/a6407/sophia-amoruso-flywheel.jpg 400w", "/static/88d2e112d4c564f3f6b407c6f45017ef/a24e6/sophia-amoruso-flywheel.jpg 800w", "/static/88d2e112d4c564f3f6b407c6f45017ef/4dc06/sophia-amoruso-flywheel.jpg 824w"],
          "sizes": "(max-width: 824px) 100vw, 824px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`There are a wide variety of niche’s to become an authority in. From vegan
cooking to outdoor survival to the FIRE movement.`}</p>
    <p>{`Looking at the business models. I think books are shit. I think courses are
great. Even small niche authorities like Tiago Forte and David Perell are
pulling `}{`$`}{`1M+ per year on courses.`}</p>
    <p>{`I think a fund is a no brainer if you’re in the business niche. I’m surprised
more of them don’t do merch. And the biggest untapped opportunity is to release
tools that help your audience live the lifestyle you preach.`}</p>
    <p>{`For example, I just had a chrome extension made that helps people do their OBT
everyday.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`BTW....Hit reply with "send me that chrome extension, shaan!", if you wanna
try it out and let me know It’s a simple prototype, but I’ve been using it for 2
weeks and I actually love it.`}</em></strong></p>
    <p>{`Here’s my take on the solopreneur model..`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "916px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgT/2gAMAwEAAhADEAAAAetKy3nY0T//xAAZEAACAwEAAAAAAAAAAAAAAAAAEAECETH/2gAIAQEAAQUCItqw6v/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABYQAAMAAAAAAAAAAAAAAAAAAAABIP/aAAgBAQAGPwIc/wD/xAAcEAEAAgEFAAAAAAAAAAAAAAABAEEhEBExUfH/2gAIAQEAAT8hVqNgHDdk3IjnqB40/9oADAMBAAIAAwAAABDPH//EABcRAQADAAAAAAAAAAAAAAAAAAEQESH/2gAIAQMBAT8QErY//8QAFxEAAwEAAAAAAAAAAAAAAAAAARARQf/aAAgBAgEBPxCHF//EABsQAQADAQADAAAAAAAAAAAAAAEAESExQXGB/9oACAEBAAE/EGUMN1S6CAoDWC+l+IMYxFcKsYOEoG0wPuAHAn//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Shaan's Flywheel",
          "title": "Shaan's Flywheel",
          "src": "/static/e9dc6b6af0d5f0740f47869a1714c791/57089/shaan-solopreneur-flywheel.jpg",
          "srcSet": ["/static/e9dc6b6af0d5f0740f47869a1714c791/a6407/shaan-solopreneur-flywheel.jpg 400w", "/static/e9dc6b6af0d5f0740f47869a1714c791/a24e6/shaan-solopreneur-flywheel.jpg 800w", "/static/e9dc6b6af0d5f0740f47869a1714c791/57089/shaan-solopreneur-flywheel.jpg 916w"],
          "sizes": "(max-width: 916px) 100vw, 916px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`I think the weakest part right now is my “niche authority”.`}</p>
    <p>{`Right now I’m basically positioning myself as the “first million guy” aka.. I
help you make your first million bucks by giving out ideas & execution
frameworks....but maybe that’s too abstract.`}</p>
    <p><strong parentName="p">{`Not sure. Curious what you think. Hit reply and tell me! Seriously, do it.`}</strong></p>
    <p>{`Right now I’m still a couple years behind the other folks..but I’m gonna get
there… soon 😈`}</p>
    <p>{`Alright - I’m outta here for now. Oh, and i got a special email for you this
sunday.`}</p>
    <p>{`-shaan`}</p>
    <Links links={[{
      title: LinkLabel.Template,
      links: [{
        url: 'http://tweettunnel.com/reverse3.php?ga=0&b=y&id=339061487&pn=2',
        label: 'TweetTunnel'
      }, {
        url: 'https://socialblade.com/twitter/user/apompliano',
        label: 'Social Blade Pomp Stats'
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.building_the_flywheel.label,
      slug: slugify(postMap.building_the_flywheel.slug)
    }} nextButton={{
      text: postMap.my_life_vision.label,
      slug: slugify(postMap.my_life_vision.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      